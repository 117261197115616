<template>
  <div class="PolylineLibrary">
    <AssetsBin
      @category="getListData"
      @select="handleSelect"
      @emptyList="handleEmptyList"
    />
    <AssetsList
      @select="handleSelect"
      @search="handleSearch"
      @handleAllChecked="handleAllChecked"
      :checkedArr="checkedArr"
      :paging="paging"
      :select="select"
      ref="msg"
    />
    <AddPolylineStyle @refresh="handleRefresh" />
    <!-- 列表数据 -->
    <div class="PolylineLibrary-box" v-loading="loading">
      <div
        class="PolylineLibrary-item"
        v-for="(item, index) in listData"
        :key="index"
        @mouseenter="mouseenter(item)"
        @mouseleave="mouseleave(item)"
      >
        <!-- 列表项头部 -->
        <div class="PolylineLibrary-item-header">
          <!-- 素材名称左侧 -->
          <el-tooltip
            :disabled="item.name.length < 17"
            :content="item.name"
            placement="right"
            effect="light"
          >
            <div class="PolylineLibrary-item-header-left">
              {{ item.name }}
            </div>
          </el-tooltip>
          <!-- 右侧收藏、编辑、选中按钮 -->
          <div class="PolylineLibrary-item-header-right">
            <list-tooltip content="编辑">
              <i
                class="icon icon-list-edit"
                @click="handleEdit(item)"
                v-show="
                  !select &&
                  item.mouse &&
                  search.system != 1 &&
                  search.typeNum == '2'
                "
              ></i>
            </list-tooltip>
            <list-tooltip content="取消收藏">
              <i
                class="icon icon-list-collect1"
                v-show="
                  (!select && item.collection == 1) || search.typeNum >= '6'
                "
                @click="handleCollect(item)"
              ></i>
            </list-tooltip>
            <el-dropdown
              trigger="click"
              ref="dropdown"
              @visible-change="val => dropdownChange(val, '6', index)"
              :hide-on-click="false"
            >
              <list-tooltip content="收藏">
                <i
                  class="icon icon-list-collect"
                  v-show="!select && item.mouse && item.collection == 0"
                  @click="handleCollect(item)"
                ></i>
              </list-tooltip>
              <el-dropdown-menu slot="dropdown">
                <FileDialog
                  @dropdownClose="dropdownClose(index)"
                  @handleCheck="id => handleCheck(id, item, index)"
                  ref="FileDialog"
                />
              </el-dropdown-menu>
            </el-dropdown>
            <i
              :class="
                item.checked
                  ? 'icon icon-list-checked1'
                  : 'icon icon-list-checked'
              "
              v-if="select"
              @click="handleChecked(item)"
            ></i>
          </div>
        </div>
        <!-- 样式预览 -->
        <div class="style-preview">
          <div
            class="style-preview-item"
            v-for="(color, index) in item.fillColor"
            :key="'1_' + index"
            :style="{ backgroundColor: color }"
          ></div>
        </div>
      </div>
      <div v-if="!listData[0] && !loading" class="PolylineLibrary-emptyBox">
        <div v-if="emptyStatus == 'emptyData'">
          <img src="~a/workbench/materiaLibrary/emptyData.png" />
          <p class="emptyText">暂无素材</p>
        </div>

        <div v-else-if="emptyStatus == 'emptySearch'">
          <img src="~a/workbench/materiaLibrary/emptySearch.png" />
          <p class="emptyText">抱歉，没有发现相关的内容</p>
          <p class="emptyTextSearch">换个关键词试试吧</p>
        </div>

        <div v-else-if="emptyStatus == 'emptyNetwork'">
          <img src="~a/workbench/materiaLibrary/emptyNetwork.png" />
          <p class="emptyText">抱歉，网络请求异常</p>
          <p class="emptyTextSearch">刷新页面试试吧</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileDialog from 'c/basic/FileDialog.vue';
import AddPolylineStyle from './AddPolylineStyle';
import AssetsBin from './AssetsBin.vue';
import AssetsList from './AssetsList.vue';
import ListTooltip from 'c/basic/ListTooltip.vue';
export default {
  name: 'PolylineLibrary',
  components: {
    AddPolylineStyle,
    AssetsBin,
    AssetsList,
    FileDialog,
    ListTooltip
  },
  data() {
    return {
      // 选择
      select: false,
      // 选中状态
      checked: false,
      // 选择中的数组
      checkedArr: [],
      // 鼠标经过显示
      mouse: false,
      listData: [],
      search: {
        categoryId: '', // 分类id，默认查所有
        system: '0', // 当前tab 1为系统 0为其他
        keywords: '', // 搜索关键字
        typeNum: ''
      },
      // 分页数据
      paging: {
        size: 15,
        page: 1,
        total: 0
      },
      // 状态显示，emptyData为查询数据为空状态，emptySearch为搜索数据为空状态， emptyNetwork为网络请求失败状态
      emptyStatus: '',
      // 加载动画
      loading: true
    };
  },
  computed: {
    styleCurrentPaged: {
      get() {
        return this.$store.state.materialLibrary.styleCurrentPaged;
      }
    }
  },
  methods: {
    dropdownChange(val, text, index) {
      if (val) {
        this.$refs.FileDialog[index].getFileData(text);
      } else {
        this.$refs.FileDialog[index].refreshClassify(1);
      }
    },
    // 搜索
    handleSearch(val) {
      this.search.keywords = val;
      this.getListData();
    },
    // 刷新列表数据
    handleRefresh() {
      this.getListData();
    },
    // 清空列表数据
    handleEmptyList() {
      this.listData = [];
    },
    // 获取分页数据
    handleCurrentChange(val) {
      this.paging.page = val;
      this.getListData();
    },
    // 获取数据
    getListData(data) {
      if (data) {
        this.search = data;
      }
      this.loading = true;
      const params = {
        categoryId: this.search.categoryId,
        system: this.search.system,
        keywords: this.search.keywords,
        page: this.styleCurrentPaged,
        selectCollection: this.search.system
      };
      for (var key in params) {
        params[key] === '' && delete params[key];
      }
      // 判断是否为搜索状态，用于空状态的显示效果
      this.emptyStatus = params.keywords ? 'emptySearch' : 'emptyData';
      let type =
        this.search.typeNum == '6'
          ? 'styleLineCollectionList'
          : 'styleLineList';
      this.$service.materialLibrary[type](params)
        .then(res => {
          if (res.data.status === 200) {
            if (res.data.totalPage < res.data.page && res.data.totalPage != 0) {
              this.$store.commit('materialLibrary/setStyleCurrentPaged', 1);
              this.getListData();
            }
            this.listData = res.data.data;
            res.data.data.map((item, index) => {
              const fillColor = [];
              var num = 0;
              for (let i = 0; i < item.fill.length; i++) {
                const element = item.fill.charAt(i);
                if (element == 1) {
                  fillColor.push(item.fillColor[num]);
                  num = num + 1;
                } else {
                  fillColor.push('#FFFFFF00');
                }
              }
              this.listData[index].fillColor = fillColor;
            });
            this.paging = {
              size: 15,
              page: res.data.page,
              total: res.data.total
            };
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.emptyStatus = 'emptyNetwork';
        });
    },

    // 编辑
    handleEdit(val) {
      const data = {
        title: '编辑线段样式',
        status: true,
        data: val
      };
      this.$store.commit(
        'materialLibrary/setAddPolylineStyleDialogVisible',
        data
      );
    },
    // 收藏
    handleCheck(id, item, index) {
      const params = {
        id: item.id,
        categoryId: id
      };
      this.$service.materialLibrary.styleLineCollection(params).then(res => {
        if (res.data.status === 200) {
          this.$refs.dropdown[index].visible = false;
          this.getListData();
          this.$refs.msg.handleMessageBox('收藏成功');
        }
      });
    },
    // 取消收藏
    handleCollect(data) {
      if (data.collection || this.search.typeNum == 6) {
        let params = new FormData();
        params.append('lineId', data.id);
        this.$service.materialLibrary
          .styleLineCollectionDelBatch(params)
          .then(res => {
            if (res.data.status === 200) {
              this.getListData();
              this.$refs.msg.handleMessageBox('取消收藏');
            }
          });
      }
    },
    // 选择
    handleSelect(val) {
      this.select = val;
      this.checkedArr = val === false ? '' : this.checkedArr;
    },
    // 点击取消去除方法
    removeItem(arr, item) {
      let targetIndex = arr.findIndex(itemTemp => itemTemp === item);
      if (targetIndex !== -1) {
        arr.splice(targetIndex, 1);
      }
    },
    // 选中与取消
    handleChecked(item) {
      if (!item.checked) {
        item.checked = true;
        this.checkedArr.push(item.id);
      } else {
        item.checked = false;
        this.$refs.msg.handleSelectText('全选');
        this.removeItem(this.checkedArr, item.id);
      }
    },
    // 全选
    handleAllChecked(val) {
      if (val === false) {
        for (let i = 0; i < this.listData.length; i++) {
          this.listData[i].checked = val;
        }
        this.checkedArr = [];
      } else {
        this.checkedArr = [];
        for (let i = 0; i < this.listData.length; i++) {
          if (!this.listData[i].checked) {
            for (let i = 0; i < this.listData.length; i++) {
              this.listData[i].checked = true;
              this.checkedArr.push(this.listData[i].id);
            }
            break;
          } else {
            this.listData[i].checked = false;
          }
        }
      }
      if (
        this.listData.length === this.checkedArr.length &&
        this.listData.length !== 0
      ) {
        this.$refs.msg.handleSelectText('取消全选');
      } else {
        this.$refs.msg.handleSelectText('全选');
      }
    },
    mouseenter(item) {
      this.$set(item, 'mouse', true);
    },
    mouseleave(item) {
      this.$set(item, 'mouse', false);
    },
    dropdownClose(index) {
      this.$refs.dropdown[index].visible = false;
    }
  }
};
</script>
<style lang="less" scoped>
.PolylineLibrary {
  &-box {
    height: calc(100vh - 650px);
    overflow: hidden;
    overflow-y: auto;
  }
  //素材项
  &-item {
    display: inline-block;
    margin: 0 20px 12px;
    width: 290px;
    height: 56px;
    background: #f4f7ff;
    border: 1px solid #d3e1ff;
    border-radius: 6px;
    overflow: hidden;
    // 头部
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 290px;
      height: 20px;
      background: #e2eaff;
      // 左
      &-left {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-left: 10px;
        width: 220px;
        text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本*/
        overflow: hidden;
        white-space: nowrap; /*不换行 控制单行文本*/
      }
      // 右
      &-right {
        .icon {
          margin-top: 3px;
          margin-right: 5px;
        }
      }
    }
    // 样式预览
    .style-preview {
      display: flex;
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
      margin: 12px 10px;
      background: #fff;
      background-image: url(~a/workbench/materiaLibrary/block1-bg.png);
      &-item {
        flex: 1;
        height: 10px;
      }
    }
  }
  .icon {
    cursor: pointer;
  }
  // 空状态
  &-emptyBox {
    height: calc(100vh - 641px);
    overflow: hidden;
    overflow-y: auto;
    img {
      display: flex;
      margin: 40px auto 0;
      width: 212px;
      height: 130px;
    }
    .emptyText {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      line-height: 16px;
      font-size: 16px;
      text-align: center;
      margin: 40px auto 10px;
    }
    .emptyTextSearch {
      text-align: center;
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1b1b1b;
      line-height: 16px;
      opacity: 0.5;
    }
  }
}
.el-dropdown-menu {
  background: none;
  text-align: justify;
  border: none;
  box-shadow: none;
  border-radius: none;
}
</style>
