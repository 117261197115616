<template>
  <!-- 添加数据类 -->
  <el-dialog
    :title="addPolylineStyleDialogVisible.title"
    :visible.sync="addPolylineStyleDialogVisible.status"
    width="23.59375vw"
    destroy-on-close
  >
    <el-form
      :model="form"
      :rules="materialLibraryFormRules"
      ref="ruleFormRef"
      class="form"
      label-position="left"
      label-width="5.4vw"
    >
      <el-form-item label="线段名称：" prop="name">
        <el-input v-model.trim="form.name" maxlength="20" show-word-limit />
      </el-form-item>
      <el-form-item
        label="选择分类："
        label-position="left"
        label-width="6.3vw"
        style="margin-left: -0.9vw"
        prop="categoryId"
      >
        <el-select v-model="form.categoryId" placeholder="默认为系统分类">
          <el-option
            v-for="item in categoryOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label-width="0">
        <div class="label">
          <span class="label-text">样式设置：</span>
          <span class="label-tip">选择任意区域进行颜色填充</span>
        </div>
        <div class="fillColor-select-wrap">
          <!-- 头部 -->
          <div class="fillColor-select-top">
            <div
              class="fillColor-select-top-block"
              :class="
                selectedBlockRange.indexOf(index - 1) !== -1 ? 'selected' : ''
              "
              v-for="index in form.fillColor.length"
              :key="index"
            >
              {{ index }}
            </div>
          </div>
          <!-- 块选择器 -->
          <div class="fillColor-select">
            <div
              class="fillColor-select-block-wrap"
              @mouseleave="handleBlockWrapMouseleave"
            >
              <div
                class="fillColor-select-block"
                :class="
                  selectedBlockRange.indexOf(index) !== -1 ? 'selected' : ''
                "
                v-for="(color, index) in form.fillColor"
                :key="index"
                :style="{ background: color }"
                @mousedown="handleBlockMousedown(index, color)"
                @mouseup="handleBlockMouseup(index)"
                @mouseenter="handleBlockMouseenter(index)"
              ></div>
            </div>
          </div>
          <!-- 底部 -->
          <div class="fillColor-select-bottom">
            <div class="fillColor-select-bottom-item">
              <p class="fillColor-select-bottom-item-label">填充：</p>
              <el-input
                class="color-ipt"
                v-model="color"
                disabled
                @change="handleColorChange"
              />
              <el-color-picker
                class="color-picker"
                v-model="color"
                show-alpha
                ref="picker"
                color-format="hexa"
                :predefine="predefineColors"
                @active-change="handleColorActiveChange"
                @change="handleColorChange"
              />
            </div>
            <div class="fillColor-select-bottom-item">
              <p class="fillColor-select-bottom-item-label">已选：</p>
              <el-input class="ipt" v-model="selectedNum" readonly />
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label-width="0">
        <div class="label">
          <span class="label-text">样式预览：</span>
          <span class="label-tip">
            <div class="style-preview">
              <div
                class="style-preview-item"
                v-for="(color, index) in form.fillColor"
                :key="'1_' + index"
                :style="{ backgroundColor: color }"
              ></div>
              <div
                class="style-preview-item"
                v-for="(color, index) in form.fillColor"
                :key="'2_' + index"
                :style="{ backgroundColor: color }"
              ></div></div
          ></span>
        </div>
      </el-form-item>
      <!-- 底部按钮 -->
      <el-form-item>
        <div class="form-btn-group">
          <Button
            class="form-btn cancel"
            @click="addPolylineStyleDialogVisible = false"
            >取消</Button
          >
          <Button class="form-btn confirm" @click="confirm">确定</Button>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import Button from 'c/basic/Button';
// 引入表单校验规则
import { userRulesMixin } from 'p/mixin.js';
export default {
  name: 'AddPolylineStyle',
  mixins: [userRulesMixin],
  components: {
    Button
  },
  data() {
    return {
      form: {
        // 名称
        name: '',
        // 分类id
        categoryId: '',
        // 填充位置，一共10个位置，有填充的位置为1，透明的位置为0，示例：1000100000
        fillPosition: '',
        // 填充颜色数组，16进制颜色， fillPosition有几个填充位置就需要传几个颜色
        fillColor: [
          '#000000FF',
          '#000000FF',
          '#000000FF',
          '#000000FF',
          '#000000FF',
          '#FFFFFFFF',
          '#FFFFFFFF',
          '#FFFFFFFF',
          '#FFFFFFFF',
          '#FFFFFFFF'
        ],
        id: ''
      },
      // 预定色
      predefineColors: [
        '#FFFFFFFF',
        '#000000FF',
        '#ff0000FF',
        '#F9F400FF',
        '#0000FFFF',
        '#008000FF',
        '#90ee90FF',
        '#00ced1FF',
        '#1e90ffFF',
        '#c71585FF',
        '#797979FF',
        '#FAD400FF',
        '#FF7800FF',
        '#FF4500AD',
        '#90F09080',
        '#1F93FFBA',
        '#c7158577'
      ],
      categoryOptions: [],
      color: '#FFFFFFFF',
      selectedNum: 0,
      selectBlockState: false,
      selectedBlockRange: [],

      // rgba透明度转hexa对应值
      lucencyColor: {
        100: 'FF',
        99: 'FC',
        98: 'FA',
        97: 'F7',
        96: 'F5',
        95: 'F2',
        94: 'F0',
        93: 'ED',
        92: 'EB',
        91: 'E8',
        90: 'E6',
        89: 'E3',
        88: 'E0',
        87: 'DE',
        86: 'DB',
        85: 'D9',
        84: 'D6',
        83: 'D4',
        82: 'D1',
        81: 'CF',
        80: 'CC',
        79: 'C9',
        78: 'C7',
        77: 'C4',
        76: 'C2',
        75: 'BF',
        74: 'BD',
        73: 'BA',
        72: 'B8',
        71: 'B5',
        70: 'B3',
        69: 'B0',
        68: 'AD',
        67: 'AB',
        66: 'A8',
        65: 'A6',
        64: 'A3',
        63: 'A1',
        62: '9E',
        61: '9C',
        60: '99',
        59: '96',
        58: '94',
        57: '91',
        56: '8F',
        55: '8C',
        54: '8A',
        53: '87',
        52: '85',
        51: '82',
        50: '80',
        49: '7D',
        48: '7A',
        47: '78',
        46: '75',
        45: '73',
        44: '70',
        43: '6E',
        42: '6B',
        41: '69',
        40: '66',
        39: '63',
        38: '61',
        37: '5E',
        36: '5C',
        35: '59',
        34: '57',
        33: '54',
        32: '52',
        31: '4F',
        30: '4D',
        29: '4A',
        28: '47',
        27: '45',
        26: '42',
        25: '40',
        24: '3D',
        23: '3B',
        22: '38',
        21: '36',
        20: '33',
        19: '30',
        18: '2E',
        17: '2B',
        16: '29',
        15: '26',
        14: '24',
        13: '21',
        12: '1F',
        11: '1C',
        10: '1A',
        9: '17',
        8: '14',
        7: '12',
        6: '0F',
        5: '0D',
        4: '0A',
        3: '08',
        2: '05',
        1: '03',
        0: '00'
      }
    };
  },
  computed: {
    addPolylineStyleDialogVisible: {
      get() {
        return this.$store.state.materialLibrary.addPolylineStyleDialogVisible;
      },
      set(val) {
        this.$store.commit(
          'materialLibrary/setAddPolylineStyleDialogVisible',
          val
        );
      }
    }
  },
  methods: {
    // 鼠标滑出色块容器时
    handleBlockWrapMouseleave() {
      // 关闭选择
      this.selectBlockState = false;
    },
    // 鼠标滑入色块时
    handleBlockMouseenter(index) {
      this.handleEndBlockChange(index);
    },
    // 在色块上松开鼠标时
    handleBlockMouseup() {
      // 关闭色块选择
      this.selectBlockState = false;
    },
    // 鼠标在色块上按下时
    handleBlockMousedown(index, color) {
      // 开启色块选择
      this.selectBlockState = true;
      // 设置起点
      this.selectedBlockRange = [index];
      this.color = color;
      this.selectedNum = 1;
    },
    // 终点色块改变时
    handleEndBlockChange(index) {
      if (!this.selectBlockState) return;
      const start = this.selectedBlockRange[0];
      // 判断是否有起点，即判断是否在色块上按下鼠标
      // 避免在非色块的地方按下鼠标，然后移动到色块上松开鼠标而误判
      if (start === undefined) return;
      // 修改排序，方便后面设置颜色时好判断
      if (this.selectedBlockRange.indexOf(index) !== -1) {
        // 最后一个不删除
        if (this.selectedBlockRange.length > 1) {
          // 删除最后一个
          this.selectedBlockRange.pop();
        }
      } else {
        this.selectedBlockRange.push(index);
      }
      // 设置已选中
      this.selectedNum = this.selectedBlockRange.length;
    },
    // 颜色改变时
    handleColorChange(e) {
      this.color = this.colorChange(e);
      const color = this.color;
      // 判断是否选中
      if (this.selectedNum < 1) return;
      // 修改选中块的颜色

      for (let index = 0; index < this.form.fillColor.length; index++) {
        if (this.selectedBlockRange.indexOf(index) !== -1)
          this.form.fillColor[index] = color ? color : e;
      }
    },
    handleColorActiveChange(e) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$nextTick(() => {
            this.$refs.picker.color.value = this.colorChange(e);
          });
        }, 200);
      });
    },
    // rgba转16进制方法
    colorChange(color) {
      if (color === null) return '#FFFFFF00';

      if (color.indexOf('rgb') === 0) {
        let arr = color
          .replace(/rgba?\(/, '')
          .replace(/\)/, '')
          .replace(/[\s+]/g, '')
          .split(',');
        let a = parseFloat(arr[3] || 1),
          r = Math.floor(parseInt(arr[0] * 1 + 1) + 1 * 255),
          g = Math.floor(parseInt(arr[1] * 1 + 1) + 1 * 255),
          b = Math.floor(parseInt(arr[2] * 1 + 1) + 1 * 255);
        const lucencyColor = this.lucencyColor;
        let lucency = '';
        for (let i = 0; i < 101; i++) {
          if (i == a * 100) {
            lucency = lucencyColor[i];
          }
        }
        if (color.indexOf('rgba(0, 0, 0,') === 0) return '#000000' + lucency;
        return (
          '#' +
          ('0' + r.toString(16)).slice(-2).toUpperCase() +
          ('0' + g.toString(16)).slice(-2).toUpperCase() +
          ('0' + b.toString(16)).slice(-2).toUpperCase() +
          lucency
        );
      } else {
        return color;
      }
    },
    // 获取分类数据
    getLineList() {
      const params = {
        type: 2,
        order: 1,
        selectSystem: 0
      };
      this.$service.materialLibrary
        .styleResourceCategoryAll(params)
        .then(res => {
          if (res.data.status === 200) {
            this.categoryOptions = res.data.data;
            if (this.form.categoryId) return;
            this.form.categoryId = res.data.data[0].id;
          }
        });
    },
    confirm() {
      this.$refs.ruleFormRef.validate(valid => {
        if (valid) {
          let title =
            this.addPolylineStyleDialogVisible.title == '新增线段样式'
              ? 'styleLineAdd'
              : 'styleLineEidt';
          let fillPosition = '';
          const fillColor = this.form.fillColor;
          let formData = new FormData();
          formData.append('id', this.form.id);
          formData.append('categoryId', this.form.categoryId);
          formData.append('name', this.form.name);

          for (let i = 0; i < fillColor.length; i++) {
            const element = fillColor[i];
            if (element.length > 7 && element.slice(-2) !== '00') {
              formData.append('fillColor', element);
              fillPosition = fillPosition + '1';
            } else {
              fillPosition = fillPosition + '0';
            }
          }
          formData.append('fillPosition', fillPosition);
          this.$service.materialLibrary[title](formData).then(res => {
            if (res.data.status === 200) {
              this.$message.success(res.data.msg);
              this.$emit('refresh');
              this.$store.commit(
                'materialLibrary/setAddPolylineStyleDialogVisible',
                ''
              );
            }
          });
        }
      });
    }
  },
  watch: {
    addPolylineStyleDialogVisible: {
      immediate: true,
      handler(data) {
        this.color = '#FFFFFFFF';
        this.selectedNum = 0;
        this.selectedBlockRange = '';
        this.selectBlockState = false;
        if (data.data) {
          this.form = JSON.parse(JSON.stringify(data.data));
          this.form.fillPosition = JSON.parse(JSON.stringify(data.data.fill));
          this.getLineList();
        } else if (data.status) {
          this.form.id = '';
          this.form.name = '';
          this.form.fillPosition = '';
          this.form.categoryId = JSON.parse(JSON.stringify(data.categoryId));
          this.form.fillColor = JSON.parse(
            JSON.stringify([
              '#000000FF',
              '#000000FF',
              '#000000FF',
              '#000000FF',
              '#000000FF',
              '#FFFFFFFF',
              '#FFFFFFFF',
              '#FFFFFFFF',
              '#FFFFFFFF',
              '#FFFFFFFF'
            ])
          );
          this.getLineList();
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
// 表单lable
.label {
  display: flex;
  font-size: 16px;
  /deep/ .el-form-item__label {
    font-size: 16px !important;
  }
  &-text {
    color: #333;
  }
  // 提示
  &-tip {
    margin: auto 0;
    flex: 1;
    color: #f06012;
  }
}

/deep/.el-form-item__label {
  padding: 0 !important;
}
// 颜色选择
.fillColor-select {
  margin-bottom: 20px;
  &-wrap {
    padding: 15px 20px;
    border-radius: 4px;
    border: 1px dashed #507cff;
  }
  // 头部
  &-top {
    display: flex;
    margin-bottom: 10px;
    &-block {
      flex: 1;
      background: #f2f2f2;
      border: 1px solid #d2d2d2;
      border-left: 0;
      line-height: 30px;
      text-align: center;
      &.selected {
        background: #bfbfbf;
      }
      // 第一个元素
      &:first-child {
        border-left: 1px solid #d2d2d2;
      }
    }
  }
  // 色块选择
  &-block {
    box-sizing: border-box;
    flex: 1;
    height: 34px;
    border: 1px solid #d2d2d2;
    cursor: pointer;
    border-left: 0;
    // 第一个元素
    &:first-child {
      border-left: 1px solid #d2d2d2;
    }
    // 选中
    &.selected {
      border-top-width: 2px;
      border-bottom-width: 2px;
      border-top-color: #2ed590;
      border-bottom-color: #2ed590;
      // 选中的第一个
      &:first-child {
        border-left-width: 2px;
        border-left-color: #2ed590;
      }
      // 选中的最后一个
      &:last-child {
        border-right-width: 2px;
        border-right-color: #2ed590;
      }
    }
    // 容器
    &-wrap {
      display: flex;
      // 设置不能选中，避免影响选择色块操作
      user-select: none;
      background: url(~a/workbench/materiaLibrary/block-bg.png);
    }
  }
  // 底图颜色选择
  &-bottom {
    display: flex;
    justify-content: space-between;
    &-item {
      position: relative;
      display: flex;
      align-items: center;
      &-label {
        line-height: initial;
        white-space: nowrap;
        color: #507cff;
        font-size: 14px;
        margin-right: 6px;
      }
      /deep/ .el-input__inner {
        width: 75px;
        height: 30px;
        padding: 0 10px;
        font-size: 14px;
      }
      .color-picker {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
        /deep/ .el-color-picker {
          &__trigger {
            width: 100%;
            height: 100%;
            padding: 0;
          }
        }
      }
      .color-ipt /deep/ .el-input__inner {
        width: 130px;
        padding-right: 30px;
        padding-left: 8px;
      }
      .ipt /deep/ .el-input__inner {
        width: 75px;
        text-align: center;
      }
    }
  }
}

// 样式预览
.style-preview {
  display: flex;

  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  background: #fff;
  background-image: url(~a/workbench/materiaLibrary/block1-bg.png);
  &-item {
    flex: 1;
    height: 10px;
  }
}

.form-btn-group {
  margin-top: 0 !important;
}
</style>
